import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../services/login.service';
import { IStatus } from '../services/interfaces/istatus';
import { ILoginCreds } from '../services/interfaces/ilogin-creds';
import { IUserData } from '../services/interfaces/iuser-data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-change-user-info',
  templateUrl: './change-user-info.component.html',
  styleUrls: ['./change-user-info.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChangeUserInfoComponent implements OnInit {

  constructor(public loginService: LoginService, public route: ActivatedRoute, public router: Router) { }
  public status: IStatus = { StatusCode: -1 };
  public userCreds: ILoginCreds = { UserName: "", Password: "" };
  public userData: IUserData = { userId: -1 };
  public isSaved: boolean = false;

  ngOnInit() {
    this.loginService.getUserInformation()
      .subscribe(result => this.userData.userId = result.userId, error => console.log(error), () => this.onComplete("gui"));
  }

  changePassword(): void {
    if (this.userCreds.Password) {
      this.loginService.editUserInformation(this.userCreds, this.userData)
        .subscribe(result => this.status.StatusCode = result.StatusCode, error => console.log(error), () => this.onComplete("editPassword"));
    }
  }


  onComplete(actionType) {
    switch (actionType) {
      case "editPassword":
        if (this.status.StatusCode == 200) {
          this.isSaved = true;
          this.router.navigate(['../dashboard'], { relativeTo: this.route });
        }
        else {
          alert("N�got gick fel, l�senordet uppdaterades inte");
        }
        break;
    }
  }
}
