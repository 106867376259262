<div id="content">
  <h1>Redigera länken {{ linkName }}</h1>
  <form>
    <label for="linkdisplayname">Rubrik</label>
    <input type="text" [(ngModel)]="linkInformation.LinkDisplayName" name="linkDisplayName" id="linkdisplayname" />

    <label for="linkaddress">Rubrik</label>
    <input type="text" [(ngModel)]="linkInformation.LinkAddress" name="linkAddress" id="linkaddress" />

    <label for="linkadress">Beskrivning</label>
    <textarea [(ngModel)]="linkInformation.LinkDescription" name="linkDescription" id="linkdescription" rows="10"> </textarea>
    <button type="button" (click)="editLink()">Uppdatera {{ linkName }}</button>
  </form>
</div>
