<div id="content">
  <h1 *ngIf="sitePagesService.selectedLang == 'swe'">{{ pageInformation.PageHeader }}</h1>
  <p [innerHTML]="pageInformation.PageParagraph" *ngIf="sitePagesService.selectedLang == 'swe'"></p>
  <h1 *ngIf="sitePagesService.selectedLang == 'eng'">{{ pageInformation.PageHeaderEng }}</h1>
  <p [innerHTML]="pageInformation.PageParagraphEng" *ngIf="sitePagesService.selectedLang == 'eng'"></p>

  <form>
    <label for="contactname" *ngIf="sitePagesService.selectedLang == 'swe'">Namn</label>
    <label for="contactname" *ngIf="sitePagesService.selectedLang == 'eng'">Name</label>
    <input type="text" [(ngModel)]="contactInformation.fromAddress.name" name="name" id="contactname" />

    <label for="contactemail" *ngIf="sitePagesService.selectedLang == 'swe'">Epost</label>
    <label for="contactemail" *ngIf="sitePagesService.selectedLang == 'eng'">Email</label>
    <input type="text" [(ngModel)]="contactInformation.fromAddress.address" name="address" id="contactemail" />

    <label for="contactmessage" *ngIf="sitePagesService.selectedLang == 'swe'">Meddelande</label>
    <label for="contactmessage" *ngIf="sitePagesService.selectedLang == 'eng'">Message</label>
    <textarea [(ngModel)]="contactInformation.content" name="content" id="contactmessage" rows="10"> </textarea>

    <button type="button" (click)="sendEmail()" *ngIf="sitePagesService.selectedLang == 'swe'">Skicka meddelandet</button>
    <button type="button" (click)="sendEmail()" *ngIf="sitePagesService.selectedLang == 'eng'">Send the message</button>
  </form>
</div>
