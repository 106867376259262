import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { EditPagesComponent } from '../edit-pages/edit-pages.component';

@Injectable()
export class UnsavedContentGuard implements CanDeactivate<EditPagesComponent> {
  result: boolean = false;

  canDeactivate(component: EditPagesComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.result = component.isSaved;
    if (!this.result)
    {
      this.result = confirm("Du har inte sparat, vill du fortsätta?");
    }
    return this.result;
  }
}
