
import { throwError, Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators'
import { Injectable } from '@angular/core';
import { IPubMedInformation } from './interfaces/ipubmed-information';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';




@Injectable()
export class PubMedService {

    public url: string = `${this.configService.domain}api/pubmed`;
    constructor(public http: HttpClient, public configService: ConfigService) { }

    public getPubMedPublications(author: string, startIndex: number): Observable<IPubMedInformation[]> {
        return this.http.get(this.url + "/" + encodeURI(author) + "/" + startIndex).pipe(
            map((response: IPubMedInformation[]) => response),
            tap(data => JSON.stringify(data)),
            catchError((error: any) => throwError(console.log(error))));
    }

}
