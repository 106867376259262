import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ILinkInformation } from '../services/interfaces/link-information';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { IStatus } from '../services/interfaces/istatus';
import { SitePagesService } from '../services/site-pages.service';

@Component({
  selector: 'app-edit-links',
  templateUrl: './edit-links.component.html',
  styleUrls: ['./edit-links.component.css'],
  providers: [SitePagesService],
  encapsulation: ViewEncapsulation.None
})
export class EditLinksComponent implements OnInit {

  public error: boolean = false;
  public linkInformation: ILinkInformation = { LinkId: -1, LinkDisplayName: "", LinkAddress: "", LinkDescription: "" }
  public params: Params = null;
  public linkName: string = "";
  public status: IStatus = { StatusCode: -1 };
  public isSaved: boolean = false;
  constructor(public sitePagesService: SitePagesService, public route: ActivatedRoute, public router: Router) { }

  public ngOnInit()
  {
    this.route.params.subscribe(params => this.params = params);
    this.sitePagesService.getLinkInformation(this.params["linkId"])
      .subscribe(result => this.linkInformation = result, error => console.log(error), () => this.onComplete("getLinkInformation"));
  }

  editLink(): void {
    this.checkEnteredInfo();
    if(!this.error)
    {
      this.sitePagesService.editLink(this.linkInformation)
        .subscribe(result => this.status.StatusCode = result.StatusCode, error => console.log(error), () => this.onComplete("edit"));
    }
  }

  checkEnteredInfo(): void
  {
    this.error = false;
    $("#linkdisplayname").next("p").remove();
    $("#linkaddress").next("p").remove();

    if (!this.linkInformation.LinkDisplayName.length)
    {
      this.error = true;
      $("#linkdisplayname").after("<p>Visningsnamn saknas</p>");
    }
    if (!this.linkInformation.LinkAddress.length)
    {
      this.error = true;
      $("#linkaddress").after("<p>Adress saknas</p>");
    }
  }
  onComplete(actionType): void {
    switch (actionType) {
      case "getLinkInformation":
        this.linkName = this.linkInformation.LinkDisplayName;
        break;
      case "edit":
        if (this.status.StatusCode == 200) {
          this.isSaved = true;
          this.router.navigate(['../../dashboard'], { relativeTo: this.route });
        }
        else {
          alert("Sid�ndringarna sparades inte, d� n�got gick fel");
        }

    }
  }
}
