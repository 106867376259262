<div id="content">
  <div id="site_links_widget_left">
    <h1 *ngIf="sitePagesService.selectedLang == 'swe'">{{ pageInformation.PageHeader }}</h1>
    <p [innerHTML]="pageInformation.PageParagraph" *ngIf="sitePagesService.selectedLang == 'swe'"></p>
    <h1 *ngIf="sitePagesService.selectedLang == 'eng'">{{ pageInformation.PageHeaderEng }}</h1>
    <p [innerHTML]="pageInformation.PageParagraphEng" *ngIf="sitePagesService.selectedLang == 'eng'"></p>
    <div id="link_display">
      <div class="link" *ngFor="let linkItem of links">
        <span><a href="{{ linkItem.LinkAddress }}">{{ linkItem.LinkDisplayName }}</a></span>
        <p *ngIf="linkItem.LinkDescription.length > 0">{{ linkItem.LinkDescription }}</p>
      </div>
    </div>
  </div>
  <app-pub-med *ngIf="doneLoading"></app-pub-med>
</div>
