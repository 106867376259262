import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../services/login.service';
import { IStatus } from '../services/interfaces/istatus';
import { Router } from '@angular/router';
import { SitePagesService } from '../services/site-pages.service';
import { ConfigService } from '../services/config.service';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

    constructor(public loginService: LoginService, public sitePagesService: SitePagesService, public router: Router, public configService: ConfigService, private fALibrary: FaIconLibrary) { }
  public status: IStatus = { StatusCode: -1 };
    ngOnInit() {
        this.fALibrary.addIcons(faUserCircle);
      console.log(this.configService.domain);
    this.loginService.checkIfLoggedIn()
      .subscribe(result => this.status.StatusCode = result.StatusCode, error => console.log(error), () => this.onComplete("checkIfLoggedIn"));
  }

  logout(): void
  {
    this.loginService.logOut()
      .subscribe(result => this.status = result, error => console.log(error), () => this.onComplete("logOut"))
  }

  onComplete(actionType): void
  {
    switch (actionType)
    {
      case "logOut":
        this.loginService.isLoggedIn = false;
        this.router.navigate(['/admin']);
        break;
      case "checkIfLoggedIn":
        this.loginService.isLoggedIn = (this.status.StatusCode == 200) ? true : false;
        break;
    }
  }
}
