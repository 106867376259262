import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SitePagesService } from '../services/site-pages.service';
import { ILinkInformation } from '../services/interfaces/link-information';
import * as $ from 'jquery';
import { IStatus } from '../services/interfaces/istatus';
import { Router, ActivatedRoute } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-link',
    templateUrl: './site-links.component.html',
    styleUrls: ['./site-links.component.css'],
    providers: [SitePagesService],
    encapsulation: ViewEncapsulation.None
})
export class SiteLinksComponent implements OnInit {

    constructor(public sitePagesService: SitePagesService, public router: Router, public route: ActivatedRoute, private fALibrary: FaIconLibrary) { }

    public error: boolean = false;
    public linkInformation: ILinkInformation = { LinkId: -1, LinkDisplayName: "", LinkAddress: "", LinkDescription: "" }
    public links: ILinkInformation[] = new Array<ILinkInformation>();
    public status: IStatus = { StatusCode: -1 }

    ngOnInit() {
        this.fALibrary.addIcons(faTrash, faEdit);
        this.sitePagesService.getSiteLinks()
            .subscribe(result => this.links = result, error => console.log(error))
    }

    showCreateLinkForm(): void {
        $(document).ready(function (event) {
            $("#create_link_form").show();
        });
    }

    createLink(): void {
        this.checkInfoEntered();
        if (!this.error) {
            let newLink: ILinkInformation = { LinkId: this.linkInformation.LinkId, LinkDisplayName: this.linkInformation.LinkDisplayName, LinkAddress: this.linkInformation.LinkAddress, LinkDescription: this.linkInformation.LinkDescription }
            this.sitePagesService.createLink(this.linkInformation)
                .subscribe(result => this.status = result, error => console.log(error), () => this.onComplete("createLink", newLink));
        }
    }

    checkInfoEntered(): void {
        this.error = false;
        $("#linkaddress").next("p").remove();
        $("#linkdisplayname").next("p").remove();

        if (!this.linkInformation.LinkDisplayName.length) {
            this.error = true;
            $("#linkdisplayname").after("<p>Visningsnamn saknas</p>");
        }
        if (!this.linkInformation.LinkAddress.length) {
            this.error = true;
            $("#linkaddress").after("<p>Adress saknas</p>");
        }

    }

    navigateEditLink(linkId): void {
        this.router.navigate(['../editlink', linkId], { relativeTo: this.route });
    }

    deleteLink(linkId, arrayIndexOfLink): void {
        this.sitePagesService.deleteLink(linkId)
            .subscribe(result => this.status = result, error => console.log(error), () => this.onComplete("deleteLink", arrayIndexOfLink));
    }

    onComplete(actionType, link): void {
        switch (actionType) {
            case "createLink":
                // Redirect
                if (this.status.StatusCode == 200) {
                    $("#create_link_form").hide();
                    this.linkInformation = { LinkId: -1, LinkDisplayName: "", LinkAddress: "", LinkDescription: "" }
                    this.links.push(link);
                } else {
                    $("#create_link_form").after("<p>N�got gick fel, l�nken skapades inte</p>");
                }
                break;
            case "deleteLink":
                // Redirect
                if (this.status.StatusCode == 200) {
                    $("#create_link_form").hide();
                    this.links.splice(link, 1);
                } else {
                    $("#create_link_form").after("<p>N�got gick fel, l�nken skapades inte</p>");
                }
                break;
        }
    }
}
