import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PubMedService } from '../services/pubmed.service';
import { IPubMedInformation } from '../services/interfaces/ipubmed-information';
import * as $ from 'jquery';
import { SitePagesService } from '../services/site-pages.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBook, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-pub-med',
    templateUrl: './pub-med.component.html',
    styleUrls: ['./pub-med.component.css'],
    providers: [PubMedService],
    encapsulation: ViewEncapsulation.None
})
export class PubMedComponent implements OnInit {
    public pubMedInformationErik: IPubMedInformation[] = null;
    public pubMedInformationKerstin: IPubMedInformation[] = null;

    public pubMedInformationErikTemp: IPubMedInformation[] = null;
    public pubMedInformationKerstinTemp: IPubMedInformation[] = null;

    public dataLoaded: boolean = false
    public loadingMore: boolean = false;

    constructor(public pubMedService: PubMedService, public sitePagesService: SitePagesService, private fALibrary: FaIconLibrary) { }

    ngOnInit() {
        this.fALibrary.addIcons(faBook, faSpinner);
        this.getPublications();

        $(document).ready(function () {
            $(".authortab").click(function (this) {
                let authorToShow = $(this).attr("rel");
                $(".authortab").not(this).removeClass("active");
                $(".pubmedlisting").not("." + authorToShow).hide();
                $(this).addClass("active");
                $(".pubmedlisting" + "." + authorToShow).show();
            });
        });
    }

    getPublications(): void {
        this.pubMedService.getPubMedPublications("erik berntorp", 0)
            .subscribe(result => this.pubMedInformationErik = result, error => console.log(error), () => this.onComplete("getPubMedPublications", "erik berntorp"));
    }
    getMore(author: string, startIndex: number): void {
        this.loadingMore = true;
        this.pubMedService.getPubMedPublications(author, startIndex)
            .subscribe(result => (author == "erik berntorp") ? this.pubMedInformationErikTemp = result : this.pubMedInformationKerstinTemp = result, error => console.log(error), () => this.onComplete("getMorePubMedPublications", author));
    }

    onComplete(actionType, author: string): void {
        switch (actionType) {
            case "getPubMedPublications":
                this.dataLoaded = this.pubMedInformationErik != null;
                this.pubMedService.getPubMedPublications("kerstin berntorp", 0)
                    .subscribe(result => this.pubMedInformationKerstin = result, error => console.log(error), () => this.onComplete("getPubMedPublications", "kerstin berntorp"));
                break;
            case "getMorePubMedPublications":
                if (author == "erik berntorp") {
                    this.pubMedInformationErik = this.pubMedInformationErik.concat(this.pubMedInformationErikTemp);
                } else {
                    this.pubMedInformationKerstin = this.pubMedInformationKerstin.concat(this.pubMedInformationKerstinTemp);
                }
                this.loadingMore = false;
                break;
        }
    }

}
