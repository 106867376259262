import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Observable } from 'rxjs';

import * as $ from 'jquery';
import { ILoginCreds } from '../services/interfaces/ilogin-creds';
import { IStatus } from '../services/interfaces/istatus';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class AdminComponent implements OnInit {
    loggedIn: boolean = false;
    public ngOnInit() {
        this.loginService.checkIfLoggedIn()
            .subscribe(result => this.status.StatusCode = result.StatusCode, error => console.log(error), () => this.onComplete("checkIfLoggedIn"));

        // Jquery
        $(document).ready(function (event) {
            $("form button").click(function () {
                var username = $("#username").val();
                var password = $("#password").val();

                if (!username || !password) {
                    $("#password").next("p").remove();
                    $("#password").after("<p>Användarnamn och lösenord saknas</p>");
                } else {
                    $("#password").next("p").remove();

                }

            });
        });
    }
    constructor(public loginService: LoginService, public router: Router, public route: ActivatedRoute) { }
    public status: IStatus = { StatusCode: -1 };
    public userCreds: ILoginCreds = { UserName: "", Password: "" };
    login(): void {
        if (this.userCreds.UserName && this.userCreds.Password) {
            this.loginService.logIn(this.userCreds)
                .subscribe(result => this.status.StatusCode = result.StatusCode, error => console.log(error), () => this.onComplete("logIn"));
        }
    }

    onComplete(actionType): void {
        switch (actionType) {
            case "logIn":
                // Redirect
                if (this.status.StatusCode == 200) {
                    this.router.navigate(['./dashboard'], { relativeTo: this.route });
                    this.loginService.isLoggedIn = true;
                } else {
                    $("#password").next("p").remove();
                    $("#password").after("<p>Användarnamnet eller lösenordet var felaktigt</p>");
                }
                break;
            case "checkIfLoggedIn":
                this.loginService.isLoggedIn = (this.status.StatusCode == 200) ? true : false;
                break;
        }
    }
}
