
import { throwError,  Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators'
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'; 
import { ILoginCreds } from './interfaces/ilogin-creds';
import { RequestOptions } from '@angular/http';
import { IStatus } from './interfaces/istatus';
import { IUserData } from './interfaces/iuser-data';
import { ConfigService } from './config.service';


@Injectable()
export class LoginService {
  public url = `${this.configService.domain}api/login`;
  public isLoggedIn = false;

  constructor(public http: HttpClient, public configService: ConfigService) { }

  public logIn(userCredentials: ILoginCreds): Observable<IStatus> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.post(this.url, JSON.stringify(userCredentials), { headers, withCredentials: true }).pipe(
      map((response: IStatus) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public checkIfLoggedIn(): Observable<IStatus> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.get(this.url, { withCredentials: true }).pipe(
      map((response: IStatus) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public logOut(): Observable<IStatus> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.delete(this.url, { withCredentials: true }).pipe(
      map((response: IStatus) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public getUserInformation(): Observable<IUserData> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.get("http://localhost:59685/api/register", { withCredentials: true }).pipe(
      map((response: IUserData) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public editUserInformation(userCredentials: ILoginCreds, userData: IUserData): Observable<IStatus> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.put("http://localhost:59685/api/register/" + userData.userId, JSON.stringify(userCredentials), { headers }).pipe(
      map((response: IStatus) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }
}
