<div id="content">
  <div id="site_pages_widget">
    <h1>Hantera sidor inom Sajten</h1>
    <button type="button" (click)="showCreatePageForm()">Skapa Sida</button>
    <form id="create_page_form">
      <label for="PageName">Sidnamn</label>
      <input type="text" [(ngModel)]="pageInformation.PageName" name="PageName" id="PageName" />

      <label for="PageHeader">Rubrik</label>
      <input type="text" [(ngModel)]="pageInformation.PageHeader" name="PageHeader" id="PageHeader" />

      <label for="PageParagraph">Text</label>
      <textarea [(ngModel)]="pageInformation.PageParagraph" name="PageParagraph" id="PageParagraph" rows="10"> </textarea>

      <label for="PageHeader">Rubrik engelska</label>
      <input type="text" [(ngModel)]="pageInformation.PageHeaderEng" name="PageHeaderEng" id="PageHeadereng" />

      <label for="PageParagraph">Text engelska</label>
      <textarea [(ngModel)]="pageInformation.PageParagraphEng" name="PageParagraphEng" id="PageParagraphEng" rows="10"> </textarea>
      <button type="button" (click)="createPage()">Spara sida</button>
    </form>

    <div id="site_pages_container">
      <h2>Översikt över sidor inom sajten</h2>
      <ul>
        <li *ngFor="let page of pages"><a [routerLink]="['../editpage', page.pageId]">{{ page.PageName }}</a></li>
      </ul>
    </div>
  </div>
  <app-link *ngIf="loadLinks"></app-link>
</div>
