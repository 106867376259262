<div id="pub_med_widget">
  <div id="pubmedlistingcontainer">
    <span class="authortab active" rel="erik">Erik Berntorp</span>
    <span class="authortab" rel="kerstin">Kerstin Berntorp</span>
    <span id="pubmedlistingloading" *ngIf="!dataLoaded; else dataExists">
      <p *ngIf="sitePagesService.selectedLang == 'swe'"><fa-icon icon="spinner" [spin]="true"></fa-icon> Laddar Data från pubmed...</p>
      <p *ngIf="sitePagesService.selectedLang == 'eng'"><fa-icon icon="spinner" [spin]="true"></fa-icon> Loading Data from pubmed...</p>
    </span>

    <ng-template #dataExists>
      <div class="pubmedlisting erik" *ngIf="sitePagesService.selectedLang == 'swe'">
        <div class="item" *ngFor="let pubMedErikItem of pubMedInformationErik">
          <span class="title">Titel: {{ pubMedErikItem.Title }}</span>
          <span class="date">Datum: {{ pubMedErikItem.DatePublished }}</span>
          <span class="abstract" *ngIf="pubMedErikItem.abstract != ''">Abstrakt: {{ pubMedErikItem.Abstract }}</span>
          <span class="link_pubmed">Länk: <a href="https://www.ncbi.nlm.nih.gov/pubmed/{{ pubMedErikItem.Id }}" target="_blank"><fa-icon icon="book"></fa-icon></a></span>
        </div>
        <div class="load_more_container">
          <button type="button" class="load_more_btn" (click)="getMore('erik berntorp', pubMedInformationErik.length + 1)" *ngIf="!loadingMore">Ladda mer av Erik Berntorp</button>
          <button type="button" class="load_more_btn" disabled="disabled" *ngIf="loadingMore">Laddar mer av Erik Berntorp...</button>
        </div>
      </div>

      <div class="pubmedlisting erik" *ngIf="sitePagesService.selectedLang == 'eng'">
        <div class="item" *ngFor="let pubMedErikItem of pubMedInformationErik">
          <span class="title">Title: {{ pubMedErikItem.Title }}</span>
          <span class="date">Date: {{ pubMedErikItem.DatePublished }}</span>
          <span class="abstract" *ngIf="pubMedErikItem.abstract != ''">Abstract: {{ pubMedErikItem.Abstract }}</span>
          <span class="link_pubmed">Link: <a href="https://www.ncbi.nlm.nih.gov/pubmed/{{ pubMedErikItem.Id }}" target="_blank"><fa-icon icon="book"></fa-icon></a></span>
        </div>
        <div class="load_more_container">
          <button type="button" class="load_more_btn" (click)="getMore('erik berntorp', pubMedInformationErik.length + 1)" *ngIf="!loadingMore">Load more by Erik Berntorp</button>
          <button type="button" class="load_more_btn" disabled="disabled" *ngIf="loadingMore">Loading more by Erik Berntorp...</button>
        </div>
      </div>

      <div class="pubmedlisting  kerstin" *ngIf="sitePagesService.selectedLang == 'swe'">
        <div class="item" *ngFor="let pubMedKerstinItem of pubMedInformationKerstin">
          <span class="title">Titel: {{ pubMedKerstinItem.Title }}</span>
          <span class="date">Datum: {{ pubMedKerstinItem.DatePublished }}</span>
          <span class="abstract" *ngIf="pubMedKerstinItem.abstract != ''">Abstrakt: {{ pubMedKerstinItem.Abstract }}</span>
          <span class="link_pubmed">Länk: <a href="https://www.ncbi.nlm.nih.gov/pubmed/{{ pubMedKerstinItem.Id }}" target="_blank"><fa-icon icon="book"></fa-icon></a></span>
        </div>
        <div class="load_more_container">
          <button type="button" class="load_more_btn" (click)="getMore('kerstin berntorp', pubMedInformationKerstin.length + 1)" *ngIf="!loadingMore">Ladda mer av Kerstin Berntorp</button>
          <button type="button" class="load_more_btn" disabled="disabled" *ngIf="loadingMore">Laddar mer av Kerstin Berntorp...</button>
        </div>
      </div>

      <div class="pubmedlisting  kerstin" *ngIf="sitePagesService.selectedLang == 'eng'">
        <div class="item" *ngFor="let pubMedKerstinItem of pubMedInformationKerstin">
          <span class="title">Title: {{ pubMedKerstinItem.title }}</span>
          <span class="date">Date: {{ pubMedKerstinItem.datePublished }}</span>
          <span class="abstract" *ngIf="pubMedKerstinItem.abstract != ''">Abstract: {{ pubMedKerstinItem.abstract }}</span>
          <span class="link_pubmed">Link: <a href="https://www.ncbi.nlm.nih.gov/pubmed/{{ pubMedKerstinItem.Id }}" target="_blank"><fa-icon icon="book"></fa-icon></a></span>
        </div>
        <div class="load_more_container">
          <button type="button" class="load_more_btn" (click)="getMore('kerstin berntorp', pubMedInformationKerstin.length + 1)" *ngIf="!loadingMore">Load more by Kerstin Berntorp</button>
          <button type="button" class="load_more_btn" disabled="disabled" *ngIf="loadingMore">Loading more by Kerstin Berntorp...</button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
