<div id="content" *ngIf="!loginService.isLoggedIn; else ifLoggedIn">
  <h1>Logga in</h1>
  <form>
    <label for="username">Användarnamn</label>
    <input type="text" [(ngModel)]="userCreds.UserName" name="UserName" id="username" />

    <label for="password">Lösenord</label>
    <input type="password" [(ngModel)]="userCreds.Password" name="Password" id="password" />
    <button type="button" (click)="login()">Logga in</button>
  </form>
</div>
<ng-template #ifLoggedIn>
<router-outlet></router-outlet>
</ng-template>
