import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SitePagesService } from '../services/site-pages.service';
import { IPageInformation } from '../services/interfaces/ipage-information';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { IStatus } from '../services/interfaces/istatus';
import * as $ from 'jquery';

@Component({
  selector: 'app-edit-pages',
  templateUrl: './edit-pages.component.html',
  styleUrls: ['./edit-pages.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SitePagesService]
})
export class EditPagesComponent implements OnInit {
  public error: boolean = false;
  public pageInformation: IPageInformation = { PageId: "", PageHeader: "", PageHeaderEng: "", PageParagraph: "", PageParagraphEng: "", PageName: "", PageNameEng: "" };
  public params: Params = null;
  public pageName: string = "";
  public status: IStatus = { StatusCode: -1 };
  public isSaved: boolean = false;
  constructor(public sitePagesService: SitePagesService, public route: ActivatedRoute, public router: Router) { }

  public ngOnInit() {
    this.route.params.subscribe(params => this.params = params);
    this.sitePagesService.getPageInformation(this.params["pageId"], true)
      .subscribe(result => this.pageInformation = result, error => console.log(error), () => this.onComplete("getPageInformation"));
  }

  editPage(): void {

    this.checkEnteredInfo();
    if(!this.error)
    {
      this.sitePagesService.editPage(this.pageInformation)
        .subscribe(result => this.status.StatusCode = result.StatusCode, error => console.log(error), () => this.onComplete("edit"));
    }
  }

  checkEnteredInfo(): void
  {
    this.error = false;
    $("#pageheader").next("p").remove();
    $("#pageparagraph").next("p").remove();
    if (!this.pageInformation.PageHeader.length)
    {
      this.error = true;
      $("#pageheader").after("<p>Rubrik saknas</p>");
    }
    if (!this.pageInformation.PageParagraph.length)
    {
      this.error = true;
      $("#pageparagraph").after("<p>Text saknas</p>");
    }
  }
  onComplete(actionType): void {
    switch (actionType)
    {
      case "getPageInformation":
        this.pageName = this.pageInformation.PageName;
        break;
      case "edit":
        if (this.status.StatusCode == 200)
        {
          this.isSaved = true;
          this.router.navigate(['../../dashboard'], { relativeTo: this.route });
        }
        else
        {
          alert("Sid�ndringarna sparades inte, d� n�got gick fel");
        }
    }
  }

}
