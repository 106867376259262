<div id="content">
  <h1>Redigera sidan {{ pageName }}</h1>
  <form>
    <label for="PageHeader">Rubrik</label>
    <input type="text" [(ngModel)]="pageInformation.PageHeader" name="PageHeader" id="PageHeader" />

    <label for="PageParagraph">Text</label>
    <textarea [(ngModel)]="pageInformation.PageParagraph" name="PageParagraph" id="PageParagraph" rows="10"> </textarea>

    <label for="PageHeadereng">Rubrik engelska</label>
    <input type="text" [(ngModel)]="pageInformation.PageHeaderEng" name="PageHeaderEng" id="PageHeadereng" />

    <label for="PageParagraphEng">Text engelska</label>
    <textarea [(ngModel)]="pageInformation.PageParagraphEng" name="PageParagraphEng" id="PageParagraphEng" rows="10"></textarea>
    <button type="button" (click)="editPage()">Uppdatera {{ pageName }}</button>
  </form>
</div>
