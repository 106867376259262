import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { IStatus } from './../services/interfaces/istatus';

@Injectable()
export class CanAccessAdminGuard implements CanActivateChild {
  constructor(public loginService: LoginService, public router: Router) { }
  status: IStatus = { StatusCode: -1 };
  result: boolean = false;
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    this.result = this.loginService.isLoggedIn;
    if (!this.result) {
      this.router.navigate(["admin"]);
    }
    return this.result;

  }
}
