import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SitePagesService } from '../services/site-pages.service';
import { IPageInformation } from '../services/interfaces/ipage-information';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [Location],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

    constructor(public location: Location, public sitePagesService: SitePagesService) { }
    public pageInformation: IPageInformation = { PageId: "", PageHeader: "", PageHeaderEng: "", PageParagraph: "", PageParagraphEng: "", PageName: "", PageNameEng: "" };
    ngOnInit() {
        this.sitePagesService.getPageInformation(1)
            .subscribe(result => this.pageInformation = result, error => console.log(error));
        console.log("PageInformation: " + this.pageInformation.PageHeader);
    }
}
