import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SitePagesService } from '../services/site-pages.service';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LangComponent implements OnInit {

  constructor(public sitePagesService: SitePagesService) { }

  ngOnInit() {
   
  }

  changeLang(selectedLang): void
  {
    this.sitePagesService.selectedLang = selectedLang;
  }

}
