import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { SitePagesComponent } from './site-pages/site-pages.component';
import { EditPagesComponent } from './edit-pages/edit-pages.component';


import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CanAccessAdminGuard } from './guards/can-access-admin.guard';
import { LoginService } from './services/login.service';
import { ChangeUserInfoComponent } from './change-user-info/change-user-info.component';
import { UnsavedContentGuard } from './guards/unsaved-content.guard';
import { ContactComponent } from './contact/contact.component';
import { SiteLinksComponent } from './site-links/site-links.component';
import { EditLinksComponent } from './edit-links/edit-links.component';
import { LinksComponent } from './links/links.component';
import { PubMedComponent } from './pub-med/pub-med.component';
import { LangComponent } from './lang/lang.component';
import { SitePagesService } from './services/site-pages.service';
import { AdminComponent } from './admin/admin.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'links', component: LinksComponent },
    {
        path: 'admin',
        component: AdminComponent,
        canActivateChild: [CanAccessAdminGuard],
        children:
            [
                { path: 'dashboard', component: SitePagesComponent },
                { path: 'editpage/:pageId', component: EditPagesComponent, canDeactivate: [UnsavedContentGuard] },
                { path: 'changepassword', component: ChangeUserInfoComponent, canDeactivate: [UnsavedContentGuard] },
                { path: 'editlink/:linkId', component: EditLinksComponent, canDeactivate: [UnsavedContentGuard] }

            ]
    },
    { path: 'contact', component: ContactComponent },
    { path: '**', component: HomeComponent }];

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        FooterComponent,
        HomeComponent,
        AboutComponent,
        SitePagesComponent,
        EditPagesComponent,
        ChangeUserInfoComponent,
        ContactComponent,
        SiteLinksComponent,
        EditLinksComponent,
        LinksComponent,
        PubMedComponent,
        LangComponent,
        AdminComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(appRoutes),
        FormsModule,
        HttpClientModule,
        FontAwesomeModule
    ],
    providers: [HttpClientModule, HttpClient, CanAccessAdminGuard, LoginService, UnsavedContentGuard, SitePagesService],
    bootstrap: [AppComponent]
})
export class AppModule { }
