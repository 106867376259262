import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { SitePagesService } from '../services/site-pages.service';
import { IPageInformation } from '../services/interfaces/ipage-information';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  providers: [Location],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit {

  constructor(public location: Location, public sitePagesService: SitePagesService) { }
  public pageInformation: IPageInformation = { PageId: "", PageHeader: "", PageHeaderEng: "", PageParagraph: "", PageParagraphEng: "", PageName: "", PageNameEng: "" };
  ngOnInit() {
    this.sitePagesService.getPageInformation(2)
      .subscribe(result => this.pageInformation = result, error => console.log(error));
  }

}
