import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SitePagesService } from '../services/site-pages.service';
import { Router } from '@angular/router';
import { IPageInformation } from '../services/interfaces/ipage-information';
import { IStatus } from '../services/interfaces/istatus';

@Component({
    selector: 'app-site-pages',
    templateUrl: './site-pages.component.html',
    styleUrls: ['./site-pages.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [SitePagesService]
})
export class SitePagesComponent implements OnInit {

    constructor(public sitePagesService: SitePagesService, public router: Router) { }
    public error: boolean = false;
    public pages: IPageInformation[];
    public pageInformation: IPageInformation = { PageId: "", PageHeader: "", PageHeaderEng: "", PageParagraph: "", PageParagraphEng: "", PageName: "", PageNameEng: "" };
    public pageIdOfNewPage: string;
    public loadLinks = false;

    ngOnInit() {
        this.sitePagesService.getSitePages()
            .subscribe(result => this.pages = result, error => console.log(error), () => this.onComplete("sitePages", null));
    }

    showCreatePageForm(): void {
        $(document).ready(function (event) {
            $("#create_page_form").show();
        });
    }

    createPage(): void {
        this.checkInfoEntered();
        if (!this.error) {
            let newPage: IPageInformation = { PageId: "", PageHeader: "", PageHeaderEng: "", PageParagraph: "", PageParagraphEng: "", PageName: "", PageNameEng: "" };
            this.sitePagesService.createPage(this.pageInformation)
                .subscribe(result => this.pageIdOfNewPage = result.toString(), error => console.log(error), () => this.onComplete("createPage", newPage));
        }
    }

    checkInfoEntered(): void {
        this.error = false;
        $("#pagename").next("p").remove();
        $("#pageheader").next("p").remove();
        $("#pageparagraph").next("p").remove();

        if (!this.pageInformation.PageName) {
            $("#pagename").after("<p>Sidnamn saknas</p>");
        }
        if (!this.pageInformation.PageHeader.length) {
            $("#pageheader").after("<p>Rubrik saknas</p>");
        }
        if (!this.pageInformation.PageParagraph.length) {
            $("#pageparagraph").after("<p>Text saknas<p>");
        }
    }

    onComplete(actionType, page: IPageInformation): void {
        switch (actionType) {
            case "sitePages":
                this.loadLinks = true;
                break;
            case "createPage":
                this.pageInformation = { PageId: "", PageHeader: "", PageHeaderEng: "", PageParagraph: "", PageParagraphEng: "", PageName: "", PageNameEng: "" };
                $("#create_page_form").hide();
                page.PageId = this.pageIdOfNewPage;
                this.pages.push(page);
        }
    }

}
