import { throwError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IEmailMessage } from './interfaces/iemail-message';
import { ConfigService } from './config.service';

@Injectable()
export class ContactService {

  public url = `${this.configService.domain}api/contact`;

  constructor(public http: HttpClient, public configService: ConfigService) { }

  public sendContactMessage(emailMessage: IEmailMessage): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    this.http.post(this.url, JSON.stringify(emailMessage), { headers });
    return new Observable<void>();
  }

}
