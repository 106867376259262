import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IPageInformation } from '../services/interfaces/ipage-information';
import { IEmailMessage } from '../services/interfaces/iemail-message';
import { ContactService } from '../services/contact.service';
import { SitePagesService } from '../services/site-pages.service';
import * as $ from 'jquery';
import { error } from 'protractor';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  providers: [ContactService],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {

  public pageInformation: IPageInformation = { PageId: "", PageHeader: "", PageHeaderEng: "", PageParagraph: "", PageParagraphEng: "", PageName: "", PageNameEng: "" };
  public contactInformation: IEmailMessage =
    {
      fromAddress:
        {
          name: "",
          address: "",
        },
      toAddress:
        {
          name: "",
          address: ""
        },
      content: "", subject: "Meddelande fr�n Berntorp Medical Consulting AB Hemsida"
    }
  ngOnInit()
  {
    this.sitePagesService.getPageInformation(4)
      .subscribe(result => this.pageInformation = result, error => console.log(error));
  }

  constructor(public sitePagesService: SitePagesService, public contactService: ContactService) { }

  sendEmail(): void
  {
    $("form, input, textarea").next("p").remove();
    let errors: boolean = false;
    if (!this.contactInformation.fromAddress.name)
    {
      errors = true;
      $("#contactname").after((this.sitePagesService.selectedLang == "swe") ? "<p>Namn saknas</p>" : "<p>Name missing</p>");
    }
    if (!this.contactInformation.fromAddress.address)
    {
      errors = true;
      $("#contactemail").after((this.sitePagesService.selectedLang == "swe") ? "<p>Epost saknas</p>" : "<p>Email missing</p>");
    }
    if (!this.contactInformation.content)
    {
      errors = true;
      $("#contactmessage").after((this.sitePagesService.selectedLang == "swe") ? "<p>Mesddelande saknas</p>" : "<p>Message missing</p>");
    }
    if (!errors)
    {
      this.contactService.sendContactMessage(this.contactInformation).subscribe();
      $("form").after((this.sitePagesService.selectedLang == "swe") ? "<p>Tack, ditt meddelande skickades." : "<p>Thank you, your message was sent.");
    }
  }

}
