import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

    constructor() { }

    public isDev: boolean = window.location.href.indexOf("localhost") > -1;
    public domain: string = (this.isDev) ? "http://localhost:59685/":"http://www.berntorpmedconsulting.com/";
}
