<header>
  <div id="imgcontainer">
    <a [routerLink]="['/']">
      <img src="../../assets/images/bmc_loggo.png" alt="Laggo Berntorp Medical Constlting" />
    </a>
  </div>
  <nav id="main-nav">
    <ul>
      <li>
        <a [routerLink]="['/']" *ngIf="sitePagesService.selectedLang == 'swe'">Hem</a>
        <a [routerLink]="['/']" *ngIf="sitePagesService.selectedLang == 'eng'">Home</a>
      </li>
      <li>
        <a [routerLink]="['/about']" *ngIf="sitePagesService.selectedLang == 'swe'">Om Oss</a>
        <a [routerLink]="['/about']" *ngIf="sitePagesService.selectedLang == 'eng'">About Us</a>
      </li>
      <li>
        <a [routerLink]="['/links']" *ngIf="sitePagesService.selectedLang == 'swe'">Länkar & artiklar</a>
        <a [routerLink]="['/links']" *ngIf="sitePagesService.selectedLang == 'eng'">Links & articles</a>
      </li>
    </ul>
  </nav>
  <nav id="login-nav" *ngIf="loginService.isLoggedIn">
    <ul>
      <li>
        <fa-icon [icon]="['fas','user-circle']"></fa-icon>
        <ul id="sub-nav">
          <li><a [routerLink]="['/admin/dashboard']">Översikt</a></li>
          <li><a [routerLink]="['/admin/changepassword']">Byt lösenord</a></li>
          <li><a (click)="logout()">Logga ut</a></li>
        </ul>
      </li>
    </ul>
  </nav>
</header>
<router-outlet></router-outlet>
