
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators'
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ILoginCreds } from './interfaces/ilogin-creds';
import { RequestOptions } from '@angular/http';
import { IPageInformation } from './interfaces/ipage-information';
import { IStatus } from './interfaces/istatus';
import { ILinkInformation } from './interfaces/link-information';
import { ConfigService } from './config.service';
@Injectable()
export class SitePagesService {
  public url = `${this.configService.domain}api/sitepages`
  public linkConfiguratorUrl = `${this.configService.domain}api/sitelinks`;
  public selectedLang: string = "swe";

  constructor(public http: HttpClient, public configService: ConfigService) { }


  public getSitePages(): Observable<IPageInformation[]> {
    return this.http.get(this.url).pipe(
      map((response: IPageInformation[]) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public getPageInformation(pageId: number, fromEdit: boolean = false): Observable<IPageInformation> {
    return this.http.get(this.url + "/" + pageId + "/" + fromEdit).pipe(
      map((response: IPageInformation) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public createPage(pageInformation: IPageInformation): Observable<number> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.post(this.url, JSON.stringify(pageInformation), { headers }).pipe(
      map((response: number) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public editPage(pageInformation: IPageInformation): Observable<IStatus> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.put(this.url + "/" + pageInformation.PageId, JSON.stringify(pageInformation), { headers }).pipe(
      map((response: IStatus) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public createLink(linkInformation: ILinkInformation): Observable<IStatus> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.post(this.linkConfiguratorUrl, JSON.stringify(linkInformation), { headers }).pipe(
      map((response: IStatus) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public editLink(linkInformation: ILinkInformation): Observable<IStatus> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.put(this.linkConfiguratorUrl + "/" + linkInformation.LinkId, JSON.stringify(linkInformation), { headers }).pipe(
      map((response: IStatus) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public getLinkInformation(linkId: number): Observable<ILinkInformation> {
    return this.http.get(this.linkConfiguratorUrl + "/" + linkId).pipe(
      map((response: ILinkInformation) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public getSiteLinks(): Observable<ILinkInformation[]> {
    return this.http.get(this.linkConfiguratorUrl).pipe(
      map((response: ILinkInformation[]) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

  public deleteLink(linkId: number): Observable<IStatus> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf8');
    return this.http.delete(this.linkConfiguratorUrl + "/" + linkId, { headers }).pipe(
      map((response: IStatus) => response),
      tap(data => JSON.stringify(data)),
      catchError((error: any) => throwError(console.log(error))));
  }

}
