<span id="language_selection">
  <a [routerLink]="['/admin']">Admin</a> |
  <a [routerLink]="['/contact']" *ngIf="sitePagesService.selectedLang == 'swe'">Kontakta Oss</a>
  <a [routerLink]="['/contact']" *ngIf="sitePagesService.selectedLang == 'eng'">Contact Us</a>
  |
  <span *ngIf="sitePagesService.selectedLang == 'swe'">Valt spr�k: </span>
  <span *ngIf="sitePagesService.selectedLang == 'eng'">Chosen language: </span>
  <span id="swe" (click)="changeLang('swe')" *ngIf="sitePagesService.selectedLang == 'eng'">English</span>
  <span id="eng" (click)="changeLang('eng')" *ngIf="sitePagesService.selectedLang == 'swe'">Svenska</span>
</span>
