import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SitePagesService } from '../services/site-pages.service';
import { ILinkInformation } from '../services/interfaces/link-information';
import { IPageInformation } from '../services/interfaces/ipage-information';

@Component({
    selector: 'app-links',
    templateUrl: './links.component.html',
    styleUrls: ['./links.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class LinksComponent implements OnInit {
    public pageInformation: IPageInformation = { PageId: "", PageHeader: "", PageHeaderEng: "", PageParagraph: "", PageParagraphEng: "", PageName: "", PageNameEng: "" };
    public links: ILinkInformation[] = new Array<ILinkInformation>();
    public doneLoading: boolean = false;
    constructor(public sitePagesService: SitePagesService) { }

    ngOnInit() {
        this.sitePagesService.getPageInformation(3)
            .subscribe(result => this.pageInformation = result, error => console.log(error), () => this.sitePagesService.getSiteLinks()
                    .subscribe(result => this.links = result, error => console.log(error), () => this.doneLoading = true));
    }

}
