<div id="site_links_widget">
  <h1>Hantera länkar</h1>
  <button type="button" (click)="showCreateLinkForm()">Skapa Länk</button>
  <form id="create_link_form">
    <label for="linkdisplayname">Visningsnamn</label>
    <input type="text" [(ngModel)]="linkInformation.LinkDisplayName" name="linkDisplayName" id="linkdisplayname" />

    <label for="linkadress">Adress</label>
    <input type="text" [(ngModel)]="linkInformation.LinkAddress" name="linkAddress" id="linkaddress" />

    <label for="linkdescription">Beskrivning</label>
    <textarea [(ngModel)]="linkInformation.LinkDescription" name="linkDescription" id="linkdescription" rows="10"> </textarea>
    <button type="button" (click)="createLink()">Spara Länk</button>
  </form>
  <div id="site_links_container">
    <h2>L�nkar</h2>
    <p *ngIf="!links.length">Det finns inga l�nkar</p>
    <div class="link" *ngFor="let linkItem of links; let i = index">
      <span><a href="{{ linkItem.LinkAddress }}">{{ linkItem.LinkDisplayName }}</a></span>
      <p *ngIf="linkItem.LinkDescription.length">{{ linkItem.LinkDescription }}</p>
      <div [ngClass]="{'link_actions': !linkItem.LinkDescription.length, 'link_actions_no_margin': linkItem.LinkDescription.length }">
          <fa-icon [icon]="['fas', 'trash']" size="2x" (click)="deleteLink(linkItem.LinkId, i)"></fa-icon>
          <!--<span class="fas fas-2x fas-trash delete" (click)="deleteLink(linkItem.LinkId, i)"></span>-->
          <fa-icon icon="edit" size="2x" (click)="navigateEditLink(linkItem.LinkId)"></fa-icon>
          <!--<span class="fas fas-2x fas-edit edit" (click)="navigateEditLink(linkItem.LinkId)"></span>-->
      </div>
    </div>
  </div>
</div>
